import PropTypes from "prop-types"
import React from "react"
import Select from "react-select"
import { MdError } from "react-icons/md"
import { useField, splitFormProps } from "react-form"
import "./select-box.scss"
import Label from "../label/label"
import ErrorMessage from "../error-message/error-message"

const SelectBox = props => {
  // Let's use splitFormProps to get form-specific props
  const [
    field,
    fieldOptions,
    { label, mandatory, description, errorMessage, ...rest },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions)
  return (
    <div className="select-box-wrapper">
      <Label label={label} mandatory={mandatory} description={description} />
      <div className="select-group">
        <Select
          name={field}
          value={value}
          onChange={setValue}
          {...rest}
        ></Select>
        {isTouched && error ? (
          <div
            className="icon error-icon"
            data-tip={error}
            data-event="click focus"
          >
            <MdError />
          </div>
        ) : ""}
      </div>
      {error ? <ErrorMessage errorMessage={error} /> : ""}
    </div>
  )
}

SelectBox.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  mandatory: PropTypes.bool,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default SelectBox
