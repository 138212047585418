import { useState } from "react"
import axios from "axios"

const useSuggestedKeywords = () => {
  const [keywords, setKeywords] = useState([])
  const [suggestedKeywords, setSuggestedKeywords] = useState([])
  const updateKeywords = async newKeywords => {
    const safeNewKeywords = newKeywords || []
    const sortedNew = _.sortBy(
      [..._.uniqBy([...safeNewKeywords], "value")],
      ["value"]
    )
    // handles search field change
    if (
      (keywords || []).length !== sortedNew.length ||
      sortedNew.some(
        v => (keywords || []).find(v2 => v.value === v2.value) === undefined
      )
    ) {
      console.log("updateKeywords: ", sortedNew)
      const newKeywordObjs = await Promise.all(
        sortedNew.map(k => {
          console.log("K", k)
          if (k.isNewKey) {
            return axios.post("keywords/", {seedId: `keyword_${k.value}`, term: k.value})
          }
          else {
            return axios.get("keywords/findByTerm/" + k.source.term)
          }  
        })
      )
      console.log("newKeywordObjs: ", newKeywordObjs)
      const children = await Promise.all(
        newKeywordObjs
          .filter(
            k => {
              return k && k.data && k.data.keyword && k.data.keyword.children && k.data.keyword.children.length > 0;
            }
          )
          .map(k => {
            return axios.get("keywords/findByTerms/" + k.data.keyword.children);
          })
      )
      const newSuggested = children
        .map(c => c.data.keywords)
        .reduce((acc, val) => acc.concat(val), [])

      console.log("newSuggested: ", newSuggested)
      setSuggestedKeywords(
        newSuggested.filter(
          s =>
            sortedNew.find(
              k => k.value.toLowerCase() === s.term.toLowerCase()
            ) === undefined
        )
      )
      setKeywords(sortedNew)
    }
  }
  return [suggestedKeywords, updateKeywords]
}

export default useSuggestedKeywords
