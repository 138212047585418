import axios from "axios"
import http from "http"
import _ from "lodash"

// TODO utilize this in the findByTerm(s) functions. We could check here first before going to the server.
const people = []

/**
 *
 * @param {array} peopleObjects a collection of objects containing a people property ( containing people objects, not strings)
 */
export const getPeopleForSave = (peopleObjects, peopleFields) => {
  if (!peopleFields) {
    peopleFields = ["peopleTags"]
  }

  var peopleStrings = []
  _.map(peopleObjects, peopleObj => {
    _.forEach(peopleFields, peopleField => {
      if (peopleObj[peopleField] && peopleObj[peopleField].length > 0) {
        peopleStrings.push(
          peopleObj[peopleField].map(k => k.value).join(",")
        )
      }
    })
  })

  return peopleStrings.join(",").trim()
}

export const parsePeopleForAutoComplete = peopleTags => {
  const parsed = _.map(peopleTags, peopleTag => {
    return {
      value: peopleTag.name,
      source: {
        _id: peopleTag._id
      }
    }
  })
  return parsed
}

export const findByName = async name => {
  return new Promise(function (resolve, reject) {
    axios.get("/people/findByName/" + name).then(
      response => {
        const people = response.data.people
        if (people) {
          resolve(people)
        } else {
          console.log("findByTerm ERROR: No people found")
          resolve(null)
        }
      },
      function (error) {
        console.log("findByTerm ERROR: ".error)
        reject(error)
      }
    )
  })
}

export const findByNames = async names => {
  if (names && names.length > 0) {
    try {
      const response = await axios.get("/people/findByNames/" + names);
      const people = response.data.people
      if (people && people.length > 0) {
        return people;
      } else {
        console.log("findByNames ERROR: No people found")
        return [];
      }
    } catch (error) {
      console.log("findByNames ERROR: ".error)
      throw (error)
    }
  } else {
    return []
  }
}


export default {
  getPeopleForSave,
  parsePeopleForAutoComplete,
  findByName,
  findByNames
}