import React from "react"
import PropTypes from "prop-types"
import * as ReactIcon from "react-icons/md"
import { useField, splitFormProps } from "react-form"
import styles from "./text-area.module.scss"
import Label from "../label/label"
import ErrorMessage from "../error-message/error-message"

const TextArea = React.forwardRef((props, ref) => {
  // Let's use splitFormProps to get form-specific props
  const [
    field,
    fieldOptions,
    { label, required, mandatory, description, errorMessage, ...rest },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField(field, fieldOptions)

  return (
    <div className={styles.wrapper}>
      <Label label={label} mandatory={mandatory} description={description} />
      <textarea {...getInputProps({ ref, required, ...rest })} />
      {isValidating ? (
        <em>Validating...</em>
      ) : isTouched && error ? (
        <div
          className="icon error-icon"
          data-tip={error}
          data-event="click focus"
        >
          {React.createElement(ReactIcon["MdError"])}
        </div>
      ) : ""}
      {error ? <ErrorMessage errorMessage={error} /> : ""}
    </div>
  )
})

TextArea.defaultProps = {
  required: false,
}

TextArea.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  mandatory: PropTypes.bool,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default TextArea
