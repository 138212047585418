import React, { useState, useEffect } from "react"
import styles from "./generic-message.module.scss"
import PropTypes from "prop-types"
import {
  MdError,
  MdCheckCircle,
  MdLightbulbOutline,
  MdClose,
} from "react-icons/md"
import classNames from "classnames/bind"

const GenericMessage = ({ message, type, closeIcon, setTime }) => {
  const [isDisplay, setDisplay] = useState(true)

  useEffect(() => {

    setDisplay(true);

    if(setTime){
      const timer = setTimeout(() => {
        setDisplay(false)
      }, 4000)
      return () => clearTimeout(timer)
    }

  }, [message])

  var cx = classNames.bind(styles)
  const styleGroup = cx([type])

  return (
    <>
      {isDisplay && message ? (
        <div className={classNames(styles.wrapper, styleGroup)}>
          <div id="genericMessage" className={styles.messageWrap}>
            <div className={styles.icon}>
              {type === "error" ? <MdError /> : ""}
              {type === "success" ? <MdCheckCircle /> : ""}
              {type === "info" ? <MdLightbulbOutline /> : ""}
            </div>
            <div className={styles.message}>{message}</div>
            {closeIcon ? (
              <div className={styles.close} onClick={() => setDisplay(false)}>
                <MdClose />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

GenericMessage.defaultProps = {
  message: "Loading...",
  type: "info",
  closeIcon: false,
  setTime: false,
}

GenericMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  closeIcon: PropTypes.bool,
  setTime: PropTypes.bool,
}

export default GenericMessage
