import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SearchWrapper from "../../../common/search-wrapper/search-wrapper"
import { DataSearch } from "@appbaseio/reactivesearch"
import "./autocomplete-tags.scss"
import { MdError } from "react-icons/md"
import { useField, splitFormProps } from "react-form"
import Label from "../../../common/form/label/label"
import ErrorMessage from "../../../common/form/error-message/error-message"

// NOTE this is used for more than just the search components. Should refactor
const AutocompleteTags = props => {
  const [
    field,
    fieldOptions,
    {
      index,
      label,
      mandatory,
      description,
      dataField,
      placeholder,
      defaultTags,
      errorMessage
    },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions)

  const [tagInputVal, setTagInputVal] = useState("")
  useEffect(() => {
    setTagInputVal("")
  }, [value])

  const removeTag = i => {
    setValue(value.filter((v, j) => j !== i))
    console.log("Remove Value:", value[i])
  }

  const onAdd = val => {
    if (
      value.find(tag => tag.value.toLowerCase() === val.value.toLowerCase()) ||
      defaultTags.find(
        tag => tag.value.toLowerCase() === val.value.toLowerCase()
      )
    ) {
      return
    }
    setValue([...value, val])
    // } else if (e.key === "Backspace" && !val) {
    //   removeTag(this.state.tags.length - 1)
    // }
  }

  const onAddNew = val => {
    const newVal = {
      label: val,
      value: val,
      isNewKey: true
    }

    setValue([...value, newVal])
  }

  return (
    <div className="autocomplete-tags-wrapper">
      <Label label={label} mandatory={mandatory} description={description} />
      <div className="input-wrap">
        <div className="input-tag">
          <ul className="input-tag__tags">
            {defaultTags && defaultTags.map((tag, i) => (
              <li className="disabled" key={tag.value}>
                {tag.value}
              </li>
            ))}
            {value && value.map((tag, i) => (
              <li key={tag.value}>
                {tag.value}
                <button
                  type="button"
                  onClick={() => {
                    removeTag(i)
                  }}
                >
                  +
                </button>
              </li>
            ))}
            <li className="input-tag__tags__input">
              <SearchWrapper index={index}>
                <DataSearch
                  componentId="keyword"
                  className="autocomplete-wrapper"
                  innerClass={{
                    input: "autocomplete-input",
                  }}
                  dataField={dataField}
                  placeholder={placeholder}
                  autosuggest={true}
                  highlight={false}
                  queryFormat="or"
                  fuzziness={0}
                  showIcon={false}
                  URLParams={false}
                  value={tagInputVal}
                  onChange={v => {
                    if (
                      value.find(
                        tag => tag.value.toLowerCase() === v.toLowerCase()
                      )
                    ) {
                      return
                    }
                    setTagInputVal(v)
                  }}
                  onKeyDown={(e, t) => {
                    if (e.key === 'Enter') {
                      console.log('Enter pressed')
                      console.log(tagInputVal)
                      onAddNew(tagInputVal)
                    }
                  }} 
                  downShiftProps={{
                    onSelect: v => {
                      console.log(v)
                      onAdd(v)
                    },
                  }}
                />
              </SearchWrapper>
            </li>
          </ul>
        </div>
        {isTouched && error ? (
          <div
            className="icon error-icon"
            data-tip={error}
            data-event="click focus"
          >
            <MdError />
          </div>
        ) : ""}
      </div>
      {error ? <ErrorMessage errorMessage={error} /> : ""}
    </div>
  )
}

AutocompleteTags.defaultProps = {
  mandatory: false,
  defaultTags: []
}

AutocompleteTags.propTypes = {
  index: PropTypes.string.isRequired,
  dataField: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  description: PropTypes.string,
  defaultTags: PropTypes.array,
  errorMessage: PropTypes.string,
}

export default AutocompleteTags
