import React from "react"
import PropTypes from "prop-types"
import "./radio-button-list.scss"
import { MdError } from "react-icons/md"
import { useField, splitFormProps } from "react-form"
import ErrorMessage from "../error-message/error-message"

const RadioButtonList = props => {
  // Let's use splitFormProps to get form-specific props
  const [
    field,
    fieldOptions,
    { required, options, errorMessage, fnSetValue },

  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions)
  // Build the field
  return (
    <>
      {options.map(o => (
        <div key={o.value} className="form-radio">
          <label className="container">
            {o.label}
            <input
              type="radio"
              name={field}
              value={o.value}
              checked={o.value === value}
              onChange={e => {
                if (e.target.checked) {
                  setValue(o.value)
                  if (fnSetValue) {
                    fnSetValue(o.value)
                  }
                }
              }}
              {...{ required }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      ))}
      <div className="error-wrap">
        {isTouched && error ? (
          <div
            className="icon error-icon"
            data-tip={error}
            data-event="click focus"
          >
            <MdError />
          </div>
        ) : (
          ""
        )}
        {error ? <ErrorMessage errorMessage={error} /> : ""}
      </div>
    </>
  )
}

RadioButtonList.defaultProps = {
  required: false,
}

RadioButtonList.propTypes = {
  field: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default RadioButtonList
