import axios from "axios"
import _ from "lodash"
import util from "./util"

/**
 *
 * @param {*} location a single location object to be created
 */
export const createLocation = async location => {
  console.log('Create Location')  
  return new Promise(function(resolve, reject) {
    axios.post("/locations", location).then(
      response => {
        console.log("location created successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("createLocation error:", error)
        reject(error)
      }
    )
  })
}

export default {
  createLocation
}