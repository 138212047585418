import React from "react"
import PropTypes from "prop-types"
import "./search-wrapper.scss"
import { ReactiveBase } from "@appbaseio/reactivesearch"
import auth from "../../../services/auth"
import util from "../../../services/util"

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

function SearchWrapper({ index, children, searchPath, className }) {
  return (
    <ReactiveBase
      className={"search-wrapper" + (className ? " " + className : "")}
      app={index}
      url={`${util.baseUrl()}${searchPath}/`}
      transformRequest={request => {
        let authObj = auth.getAuth()
        if (authObj && authObj.jwt) {
          console.log("Set Authorization to: " + "Bearer " + authObj.jwt)
          request.headers["Authorization"] = "Bearer " + authObj.jwt
        }
        return request
      }}
    >
      {children}
    </ReactiveBase>
  )
}

SearchWrapper.defaultProps = {
  searchPath: "search",
}

SearchWrapper.propTypes = {
  index: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  searchPath: PropTypes.string,
  className: PropTypes.string,
}

export default SearchWrapper
